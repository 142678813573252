import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Leo Man
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/leo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Leo</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Leo Man</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Leo Man</h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-love");
                  }}>
                  <h5 className="font-semibold text-black">Leo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Leo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-man");
                  }}>
                  <h5 className="font-semibold text-black">Leo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Leo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Leo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Leo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Leo Men</p><br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Traits:</p> Royal, socially active, egoistic, kind-hearted, responsible, the perfectionist, ready to take on challenges, possessive<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Compatibility Signs for Leo Men:</p> Aries, Gemini, Libra, and Sagittarius<br/><br/>
Leo men are warm, generous, and very loving. You are usually happy and upbeat and like to make others happy, as well. You tend to gravitate toward people with positive attitudes and the ones who share your love of life. You want to be close to someone and will often try to be close to multiple people at once You are very confident in your abilities and decisions. You are used to having a lot of power and influence, and you love being in the spotlight. You get a thrill from being the center of attention, and you can be a bit self-centered sometimes. But you are also generous and caring, and you have a big heart.
 <br/><br/>
You are a natural leader, so people often look to you for advice and solutions to problems. You are confident in your abilities, so you don’t need much support. Your drive and determination help you achieve goals, and you accept all challenges coming your way. Your positive attitude helps others, and you bring out the best in everyone around you.
 <br/><br/>
You are very passionate, romantic, and sensual. At the same time, you are a smart worker, creative, and push yourself to enjoy all the luxuries in life. You are chivalrous, and even a bit old-fashion. You place a high value on love and friendship and are oftentimes seen as a knight in shining armor.
 <br/><br/>
On the dark side, you are very jealous and possessive. You always want to be on the first at any cost. You can have an explosive temper when you are insecure about your possessions. You may think that everyone is out to take what you have, even if it means hurting you and those around you.
 <br/><br/>
 
 
<p className="">Meet Your Janampatri! It’s time to learn more about your personality through the lens of your zodiac sign.</p> <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Let’s take a look at Leo's men Traits</p>
 <br/>
 <p className="playfair text-black text-xl md:text-3xl">Royal</p><br/>
Regal and royal are the qualities always associated with a Leo man. You live life like a king, have broad shoulders, and walk with a straight posture. Leo men are majestic people who live a powerful life like a king. You want to live life lavishly. You will always present and serve high-quality work at your workplace. You have a desire to be the best, and your determination to achieve your goals inspires others.
You are a Leo man if you live like a king. You have a powerful presence, and people tend to listen when you speak. You are comfortable in the spotlight, and you like to live an extravagant lifestyle. You will always have the latest, fanciest things, and you will never be satisfied with what you have.
 <br/><br/>
 
<p className="playfair text-black text-xl md:text-3xl">Socially active</p><br/>
One may seldom see a Leo man standing in a corner alone in a room full of people. Chances are that you may find yourself standing in the center with many people thronging around you. <br/><br/>
You are a social person and you need people around you all the time. You get a kick when you get acclaim and recognition from others. You are likely to be a little self-centered and egotistical, but you can fix that! When you stand up for yourself and others, you naturally gain respect and people will like you even more.<br/><br/> 
You always want your dynamic and charismatic personality to be reflected in the eyes of the people around you. You can easily manage to enamor people.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Egoistic</p><br/>
When someone hurts your feelings or doesn’t pay you the amount of attention you think you deserve, you feel a deep sense of betrayal and get very dramatic in your reaction. You become extremely sensitive to any sort of disrespect and can take it personally. Sometimes when it’s not directed at you, you feel like someone has disrespected you.<br/><br/>
On the other side, you are gentle souls when you get respect and get your inherent enthusiasm
 <br/><br/>
 
<p className="playfair text-black text-xl md:text-3xl">Kind-hearted</p><br/>
You are a warm and giving person, always ready to help others. You are a natural leader, and people often follow your lead. You are a very protective person and tend to take things personally. You are fiercely loyal, and your friends are often the only people you'll ever let into your life. 
You will not show losing face nor deny to the person who has approached for help. This quality makes you different from others.
<br/><br/>
Responsible The high-risk, high-reward adventurers, the leaders of men, the conquerors of battles, the champions of causes – you were born to be a leader. And, when the world is at war, you will bravely manoeuvre your close ones from uncertainties. You will be awe-inspiring in your way of waging war. When the going gets tough, you will tirelessly fight for your loved ones, your country, and your goals.
The perfectionist You are a stickler for detail, and nothing is complete unless it is just so with you. You do your best to make things perfect, and it always shows. People come to you with their problems, and you are always ready to listen and offer your help. It is hard to find fault in your work or the things organized by you.
You have a very strong sense of possession, which is why you expect answers from others when you need something from them. You have no problem asking for what you want, and it is hard to find fault in your work or the things organized by you because you know exactly what you want and why you want it. This quality makes you great at keeping secrets and solving other people’s problems, but it also makes it hard for you to compromise your dignity. You don’t like to seem ungrateful for a favor, but you also don’t like to be taken advantage of.
 <br/><br/>
Responsible
The high-risk, high-reward adventurers, the leaders of men, the conquerors of battles, the champions of causes – you were born to be a leader. And, when the world is at war, you will bravely manoeuvre your close ones from uncertainties. You will be awe-inspiring in your way of waging war. When the going gets tough, you will tirelessly fight for your loved ones, your country, and your goals.
 <br/><br/>
The perfectionist
You are a stickler for detail, and nothing is complete unless it is just so with you. You do your best to make things perfect, and it always shows. People come to you with their problems, and you are always ready to listen and offer your help. It is hard to find fault in your work or the things organized by you.
 <br/><br/>
You have a very strong sense of possession, which is why you expect answers from others when you need something from them. You have no problem asking for what you want, and it is hard to find fault in your work or the things organized by you because you know exactly what you want and why you want it. 
This quality makes you great at keeping secrets and solving other people’s problems, but it also makes it hard for you to compromise your dignity. You don’t like to seem ungrateful for a favor, but you also don’t like to be taken advantage of.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Ready to take on challenges</p><br/>
Leos are fearless, and they’re always ready to take on new challenges. They’re the ones who are obsessed with learning new things and are never satisfied with their skills. They’re the ones who will try anything at least once, and they’re usually the ones who get the highest grades. They’re the leaders of the pack, and they’d rather let everyone know that they’re ready for the challenge than run off and hide in the bushes.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Possessive</p><br/>
Leo is the most possessive sign of the zodiac. When it comes to your soulmates, nothing is more important to you than keeping them to yourself. You are constantly on the lookout for any potential threats - either real or imagined - and will do everything in your power to make sure your loved ones don't get away. You are so focused on this that you can become incredibly paranoid and insecure, to the point where it interferes with your life.
 <br/><br/>
How is Leo man in love and relationships?
You fall in love in an incredibly warm and cuddly way. Your ego will get a boost after falling in love, and you will truly enjoy chasing your partner.<br/><br/>
Leo man is known for being passionate, generous, and romantic. He’s the guy who will go out of his way to make his partner happy. He’s also known for being very possessive, especially when it comes to his mate. He will do everything in his power to make sure his partner is happy.<br/><br/>
Leo is known as the sign of love and relationships. He is passionate, loyal, and dedicated to his partners and friends. He is an absolute pleasure to be with, and any woman would be lucky to have him in her life. Despite his generally positive nature, there are a few negative aspects to his personality that can come across as clingy and overbearing.
 
 <br/><br/>
 
This behavior of your shows that is not your security; it is just your style to impress her. You had a regal presence and commanded respect from your subjects. Because of your abilities, people believed that you were destined for greater things, and they looked up to you as a leader. It was time for you to discover your destiny, and face the future.
 <br/><br/>
<p className="">Get your personalized janampatri from our expert astrologers</p> 
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">How To Attract Leo Men</p><br/>
If you have a keen eye on a Leo Individuals. It’s not enough to simply be aware of Leos – you should have a keen eye on them, too, because they’re extremely hard to ignore. Here are a few tips and tricks to attract a Leo:
 <br/><br/>
Show your vitality: Leo men are intense and passionate. You want to see your passion, both at work and in your personal life. Talking about your love of rock climbing on the weekend will impress and attract them. They want to feel the same connection in a relationship, so being able to discuss your favorite movies, food, and sports will make them feel closer.<br/><br/>
Stand out from the crowd: When you’re dating a Leo, you’re going to want to make your presence known. Leos are attracted to bold, passionate individuals who won’t hesitate to chase their dreams. This can make it difficult for them to relax and enjoy someone else's company because they want to be the one doing most of the initiating. The best way to attract Leo’s attention is to be bold in what you say and do without being aggressive.<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Stay mysterious:</p>What should you do to make sure he’s not a dull date, but rather an exciting one? One way to do this is to stay unpredictable.<br/><br/>
It can be difficult to maintain a mysterious air while also keeping your date interesting. The key is to keep your date interesting without revealing too much too soon. This will help you to keep your mystery intact while also keeping your date interested. This will allow you to keep your date interested in you well into the future.
 
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
